var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "label",
    { staticClass: "cursor-pointer relative disabled:cursor-default" },
    [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.inputValue,
            expression: "inputValue",
          },
        ],
        ref: _vm.uid,
        staticClass: "disabled:opacity-75 w-5 h-5",
        attrs: {
          type: "radio",
          name: _vm.inputFieldName,
          id: _vm.inputId,
          disabled: _vm.disabled,
        },
        domProps: {
          value: _vm.fieldValue,
          checked: _vm._q(_vm.inputValue, _vm.fieldValue),
        },
        on: {
          change: [
            function ($event) {
              _vm.inputValue = _vm.fieldValue
            },
            _vm.change,
          ],
        },
      }),
      _c(
        "span",
        { attrs: { for: _vm.inputId, disabled: _vm.disabled } },
        [_vm._t("default")],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }